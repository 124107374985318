<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4">
        <span>{{ message }}</span>
        <v-form ref="form" v-model="valid">
          <v-text-field v-model="pwd1" type="password" :rules="[rules.req, (pwd1 == pwd2 || 'Las contraseñas no coinciden')]" filled dense placeholder="Contraseña"></v-text-field>
          <v-text-field v-model="pwd2" type="password" :rules="[rules.req, (pwd1 == pwd2 || 'Las contraseñas no coinciden')]" filled dense placeholder="Repetir Contraseña"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click.native="agree">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { req } from "@/utils/validations.js";
export default {
  data: () => ({
    pwd1:'',
    pwd2:'',
    dialog: false,
    resolve: null,
    valid: true,
    reject: null,
    message: null,
    title: null,
    rules: {
      req,
    },
    options: {
      color: 'primary',
      width: 490,
      zIndex: 200
    }
  }),
  methods: {
    open(title, message, options) {
      this.pwd1 = ''
      this.pwd2 = ''
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      if (this.$refs.form.validate()){
        try{
          this.$store.dispatch("changePwd",{
            user: this.$store.getters.getTokenExtraPayload.idUnidadEquipo,
            pass: this.pwd1,
          })
          this.resolve(true)
          this.dialog = false
          this.$root.$emit("snack", "Se ha cambiado la contraseña correctamente");
        }
        catch(e){
          console.log(e);
          this.$root.$emit("snack", "Error al cambiar la contraseña");
        }
      }
    },
  }
}
</script>